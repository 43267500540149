<template>
  <questionnaire-initial-page-template
    :language="locale"
    :language-options="languageOptions"
    without-footer
    @change-language="onChangeLanguage"
  >
    <your-data-is-safe-form
      :object-medical-use="objectMedicalUse"
      :marketing-use="marketingUse"
      @continue="$emit('continue', $event)"
      @cancel="$emit('cancel', $event)"
      @input="$emit('input', $event)"
    ></your-data-is-safe-form>
  </questionnaire-initial-page-template>
</template>

<script>
import QuestionnaireInitialPageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnaireInitialPageTemplate';
import YourDataIsSafeForm from '@/modules/questionnaire/pages/your-data-is-safe/YourDataIsSafeForm';

export default {
  name: 'YourDataIsSafeTemplate',
  components: { QuestionnaireInitialPageTemplate, YourDataIsSafeForm },
  props: {
    objectMedicalUse: {
      type: Boolean,
      default: false
    },
    marketingUse: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: ''
    },
    languageOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onChangeLanguage(code) {
      this.$emit('change-language', code);
    }
  }
};
</script>
