<template>
  <your-data-is-safe-template
    :object-medical-use="objectMedicalUse"
    :marketing-use="marketingUse"
    :locale="patient.locale"
    :language-options="languageOptions"
    @change-language="onLanguageSelect"
    @input="onFieldChange"
    @cancel="onCancel"
    @continue="onAgreeAndContinue"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import YourDataIsSafeTemplate from '@/modules/questionnaire/pages/your-data-is-safe/YourDataIsSafeTemplate';

import { getHashedId } from '@/utils';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import disableScrollOnThisPage from '@/modules/questionnaire/mixins/disableScrollOnThisPage';

import { types as questTypes } from '@/modules/questionnaire/store/types';

export default {
  name: 'PageYourDataIsSafe',
  components: { YourDataIsSafeTemplate },
  mixins: [changeLanguageMixin, disableScrollOnThisPage],
  computed: {
    ...mapState({ consent: state => state.questionnaire.consent }),
    ...mapGetters({
      doctor: questTypes.getters.GET_DOCTOR,
      patient: questTypes.getters.GET_PATIENT
    }),
    objectMedicalUse: {
      get() {
        return !this.consent.medicalUse;
      },
      set(value) {
        this.setConsent({ ...this.consent, medicalUse: !value });
      }
    },
    marketingUse: {
      get() {
        return this.consent.marketingUse;
      },
      set(value) {
        this.setConsent({ ...this.consent, marketingUse: value });
      }
    }
  },
  methods: {
    ...mapMutations({
      setConsent: questTypes.mutations.SET_CONSENT,
      resetQuestionnaireModule: questTypes.mutations.RESET_MODULE
    }),
    ...mapActions({
      setAcceptConsent: questTypes.actions.SET_IS_CONSENTS_ACCEPTED,
      updatePatient: questTypes.actions.UPDATE_PATIENT
    }),
    async onAgreeAndContinue() {
      this.setConsent({ ...this.consent, implied: true });

      await this.setAcceptConsent(true);
      await this.$router.replace({ name: 'SelectYourDiagnostic' });
    },
    async onLanguageSelect(locale) {
      await this.updatePatient({ locale });

      this.onChangeLanguage(locale);
    },
    onCancel() {
      this.$router.replace({
        name: 'QuestionnaireForDoctor',
        params: {
          lang: this.locale,
          hash: getHashedId(this.doctor.id)
        }
      });

      this.resetQuestionnaireModule();
    },
    onFieldChange({ fieldName, value }) {
      this[fieldName] = value;
    }
  }
};
</script>
