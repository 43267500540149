<template>
  <div class="questionnaire-template questionnaire-background">
    <questionnaire-header
      class="questionnaire-template__header"
      :user-info="userInfo"
      :doctor-info="doctorInfo"
      :language="language"
      :language-options="languageOptions"
      @change-language="$emit('change-language', $event)"
    />

    <div class="questionnaire-template__container initial-page-container" :class="containerClasses">
      <slot></slot>
    </div>

    <template v-if="!withoutFooter">
      <slot name="footer">
        <div class="questionnaire-template__footer initial-page-footer">
          <div class="initial-page-footer-buttons-wrap">
            <u-button
              class="initial-page-footer-buttons-wrap__button"
              kind="text"
              icon-name="left"
              size="medium"
              @click="$emit('back')"
            >
              {{ $t('action.back') }}
            </u-button>
            <u-button
              class="initial-page-footer-buttons-wrap__button"
              kind="primary"
              size="medium"
              :disabled="isContinueDisabled"
              :loading="loading"
              @click="$emit('continue')"
            >
              {{ $t('action.continue') }}
            </u-button>
          </div>
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnaireHeader from '@/modules/questionnaire/new-design-components/QuestionnaireHeader';

export default {
  name: 'QuestionnaireInitialPageTemplate',
  components: { UButton, QuestionnaireHeader },
  props: {
    isContinueDisabled: {
      type: Boolean,
      default: false
    },
    withoutFooter: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    doctorInfo: {
      type: Object,
      default: null
    },
    userInfo: {
      type: Object,
      default: null
    },
    verticallyCenterContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    containerClasses() {
      return {
        'initial-page-container--vertically-centered': this.verticallyCenterContent
      };
    }
  },
  methods: {
    onChangeLanguage(code) {
      this.$emit('change-language', code);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~@/modules/questionnaire/assets/scss/questionnaire-background.scss';

.questionnaire-template {
  min-height: 100vh;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  --footer-height: 80px;
  --horizontal-padding: 24px;
  background-color: var(--u-color-white);
  height: var(--screen-height);
  --header-height: 60px;

  &__header {
    width: 100%;
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__container {
    overflow-y: auto;
    position: relative;
    top: var(--header-height);
    padding: 56px var(--horizontal-padding) 24px var(--horizontal-padding);
    height: calc(var(--screen-height) - var(--header-height) - var(--footer-height));
  }

  &__footer {
    height: var(--footer-height);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--u-color-grey-200);
  }
}

.initial-page-container {
  display: flex;
  justify-content: center;
}

.initial-page-footer {
  box-sizing: border-box;
  padding: 16px;
  background-color: var(--u-color-white);
}

.initial-page-footer-buttons-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 556px;
  margin: 0 auto;

  &__button {
    box-sizing: border-box;
    flex: 1 1 0;
    margin: 8px;
  }
}

@media (min-width: 400px) {
  .questionnaire-template {
    --footer-height: 80px;
  }

  .initial-page-footer {
    flex-direction: row;
  }
}

@media (min-width: $desktop-start) {
  .questionnaire-template {
    --horizontal-padding: 144px;
    --header-height: 82px;
    --footer-height: 104px;
  }

  .initial-page-container {
    &--vertically-centered {
      align-items: center;
    }
  }
}
</style>
