<template>
  <div class="safe-data-page">
    <div class="safe-data-page__title u-typography-helvetica u-text u-text--l">
      {{ $t('yourDataIsSafeWithMe.title') }}
    </div>
    <div class="safe-data-page__content">
      <div class="safe-data-page__paragraph u-typography-helvetica u-text u-text--xs">
        {{ $t('yourDataIsSafeWithMe.text.weUseAdvancedSecurity') }}
      </div>
      <i18n
        path="yourDataIsSafeWithMe.text.byClickingDeclare"
        tag="div"
        class="safe-data-page__paragraph u-typography-helvetica u-text u-text--xs"
      >
        <span>"{{ $t('action.agreeAndContinue') }}"</span>
        <a href="/terms" target="_blank" class="safe-data-page__link">{{
          $t('yourDataIsSafeWithMe.label.termsAndConditions')
        }}</a>
      </i18n>
      <div>
        <i18n
          path="yourDataIsSafeWithMe.text.byClickingIAcknowledge"
          tag="div"
          class="safe-data-page__paragraph u-typography-helvetica u-text u-text--xs"
        >
          <span>"{{ $t('action.agreeAndContinue') }}"</span>
          <a href="/patient-notice" target="_blank" class="safe-data-page__link">{{
            $t('yourDataIsSafeWithMe.text.patientInformation')
          }}</a>
        </i18n>

        <ul class="safe-data-page__list u-typography-helvetica u-text u-text--xs">
          <li>{{ $t('consent.ensureMyRegistration') }}</li>
          <li>{{ $t('consent.performSkinEvaluation') }}</li>
          <li>{{ $t('consent.communicatePersonalData') }}</li>
          <li>{{ $t('consent.sendUsefulInformation') }}</li>
          <li>{{ $t('consent.manageAndMaintain') }}</li>
        </ul>
      </div>

      <div class="safe-data-page__paragraph u-typography-helvetica u-text u-text--xs">
        {{ $t('consent.label.forClinicalResearch') }}
      </div>

      <div class="safe-data-page__form">
        <u-checkbox
          class="safe-data-page__checkbox"
          :value="objectMedicalUse"
          :label="$t('consent.forClinicalResearch.usePersonalData')"
          @input="onFieldChange('objectMedicalUse', $event)"
        />
        <u-checkbox
          class="safe-data-page__checkbox"
          :value="marketingUse"
          :label="$t('consent.forClinicalResearch.contactedByEmail')"
          @input="onFieldChange('marketingUse', $event)"
        />
      </div>
    </div>

    <div class="safe-data-page__footer safe-data-page-footer">
      <u-button
        class="safe-data-page-footer__button"
        kind="primary"
        size="medium"
        @click="$emit('continue', $event)"
        >{{ $t('action.agreeAndContinue') }}</u-button
      >
      <u-button
        class="safe-data-page-footer__button"
        kind="text"
        size="medium"
        @click="$emit('cancel', $event)"
        >{{ $t('action.cancel') }}</u-button
      >
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import { UCheckbox, UButton } from 'universkin-shared';

import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'YourDataIsSafeForm',
  components: { UCheckbox, UButton },
  props: {
    objectMedicalUse: {
      type: Boolean,
      default: false
    },
    marketingUse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollbar: null
    };
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar('.safe-data-page__content', {
      ...DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    });
  },
  methods: {
    onFieldChange(fieldName, fieldValue) {
      this.$emit('input', { fieldName, value: fieldValue });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.safe-data-page {
  display: flex;
  flex-direction: column;

  max-width: 327px;
  height: 100%;

  color: var(--u-color-graphite);

  &__title {
    margin-bottom: 24px;
  }

  &__content {
    position: relative;
    flex: 1;

    width: 100%;
    margin-bottom: 24px;
    padding-right: 15px;

    text-align: left;
  }

  &__paragraph {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    margin-top: 5px;
    padding-left: 20px;
    list-style-type: ' – ';
  }

  &__link {
    text-decoration: none;
    color: var(--u-color-graphite);
    border-bottom: 1px solid var(--u-color-graphite);
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }

  &__checkbox {
    font-family: var(--u-font-family-Gilroy);
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }

  &__footer {
    width: 484px;
    max-width: calc(100% - 24px * 2);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.safe-data-page-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__button {
    margin: 4px 0;
  }
}

/deep/ .safe-data-page__content .ps__rail-y {
  opacity: 1;
}

/deep/ .safe-data-page__content .ps__thumb-y {
  width: 3px;
}

@media (min-width: $desktop-start) {
  .safe-data-page {
    max-width: 465px;
  }
}
</style>
